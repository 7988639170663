<template>
  <div>
    <div id="introduccion" class="elemento">
      <img class="marcas" src="./../assets/almacen.jpg" />
      <p>Clifon Center distribuye una amplia gama de productos de <b>calefacción</b> y <b>fontanería</b>, aportando las mejores firmas del mercado.
      Nuestra empresa está especializada en la atención a tiendas y la rapidez en el suministro de materiales.</p>

      <div id="contacto">
        <div id="direcciones" class="direcciones">
              <span class="nombre">Clifon Center S.A.</span><br/>
              <a target="_blank" href="http://g.co/maps/v5j37">
                <span class="direccion">Calle Cistierna nº2</span><br/>
                <span class="direccion">28947 Fuenlabrada (Madrid)</span>
              </a>
        </div>
      </div>
    </div>
    <div id="tarifas" class="elemento">
      <span class="nota">Puede consultar nuestros catálogos actualizados<br/>en los siguientes enlaces.</span>
      <span class="notareducida">Clifon Center distribuye una amplia gama de productos de <b>calefacción</b> y <b>fontaneria</b>. Aquí puede consultar nuestros catálogos actualizados.</span>
      <div id="tarifas2">
        <a class="tarifa outsider" v-for="(tarifa, index) in tarifas" :key="index" :href="tarifa.url">
          <img :src="tarifa.imagen" />
          <span class="titulo">{{tarifa.titulo}} </span>
          <span class="pdf">{{tarifa.formato}}</span>
        </a>
        <a class="boton outsider" href="https://drive.google.com/drive/folders/1HFY-gdzyPVrGL8mwSJw2_WI1nxkCdrNX?usp=sharing">Más tarifas de nuestros proveedores</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'tarifas',
  data () {
    return {
      tarifas: [
        { titulo: 'Tarifa General de precios', formato: 'PDF (11 Mb)', url: 'https://www.clifoncenter.com/tarifa', imagen: 'icono_tarifa2.png' },
        { titulo: 'Muebles y Platos Olimpo', formato: 'PDF (2,63 Mb)', url: 'https://www.clifoncenter.com/olimpo', imagen: 'icono_olimpo2.png' },
        { titulo: 'Tarifa Wisa Técnico', formato: 'PDF (13,7 Mb)', url: 'https://www.clifoncenter.com/wisaTecnico', imagen: 'icono_wisa1.png' },
        { titulo: 'Wisa cisternas empotradas', formato: 'PDF (4,64 Mb)', url: 'https://www.clifoncenter.com/wisaCisternasEmpotradas', imagen: 'icono_wisa2.png' }
      ]
    }
  }
}
</script>
<!-- tarifas: [
  { titulo: 'Tarifa General de precios ', formato: 'PDF (11 Mb)', url: 'https://www.dropbox.com/s/u2ikah8gfrppl62/Clifon.pdf?dl=1', imagen: 'static/icono_tarifa2.png' },
  { titulo: 'Muebles y Platos Olimpo', formato: 'PDF (2,63 Mb)', url: 'Olimpo.pdf', imagen: 'static/icono_olimpo2.png' },
  { titulo: 'Tarifa Ramon Soler', formato: 'PDF (57,4 Mb)', url: 'Ramon_Soler_2017.pdf', imagen: 'static/icono_ramon_soler_2017.png' },
  { titulo: 'Tarifa Tres', formato: 'PDF (64,5 Mb)', url: 'Tres_2018.pdf', imagen: 'static/icono_tres.png' },
  { titulo: 'Tarifa Ariston', formato: 'PDF (14,7 Mb)', url: 'Ariston_2017.pdf', imagen: 'static/icono_ariston.png' },
  { titulo: 'Tarifa Valadares', formato: 'PDF (14,8 Mb)', url: 'Valadares_2018.pdf', imagen: 'static/icono_valadares.png' },
  { titulo: 'Tarifa Arco', formato: 'PDF (7,09 Mb)', url: 'Arco_2017.pdf', imagen: 'static/icono_arco_2017.png' },
  { titulo: 'Tarifa Riuvert', formato: 'PDF (19,7 Mb)', url: 'Riuvert_2017_01.pdf', imagen: 'static/icono_riuvert_2017_01.png' },
  { titulo: 'Tarifa Wisa Técnico', formato: 'PDF (13,7 Mb)', url: 'https://www.dropbox.com/s/9c1ubi80v1dtrql/wisa_catalogo_tecnico%202017.pdf?dl=1', imagen: 'static/icono_wisa1.png' },
  { titulo: 'Wisa cisternas empotradas', formato: 'PDF (4,64 Mb)', url: 'https://www.dropbox.com/s/jr3x72qsg0ggj09/wisa_cisternas_empotradas.pdf?dl=1', imagen: 'static/icono_wisa2.png' }
] -->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (min-width:600px) {
  .notareducida{
    display: none;
  }
  #tarifas{
    width: 390px;
  }
}
@media screen and (max-width:600px) {
  #introduccion, .nota{
    display: none;
  }
}

#introduccion{
  width: 200px;
  text-align: left;
  background: #385d72;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.nota,.notareducida{
  font-size: 1em;
  color: #c1dbea;
}
.elemento{
  margin: 0px;
  width: 100%;
  float: left;
}
#introduccion p{
  margin: 5px 15px 0 15px;
  padding:0;
}
#tarifas2{
  margin: 0 auto;
  width: 280px;
}
.tarifa{
  margin: 15px;
  float: left;
  font-size: .8em;
  width: 110px;
  text-align: center;
  color: #fff;
}
.pdf{
  color: #c1dbea;
}
.boton{
  display: block;
  width: 290px;
  background: linear-gradient(to right, rgba(104,140,161,0.2) 0%, rgba(104,140,161,0.7) 10%, rgba(104,140,161,0.7) 90%, rgba(104,140,161,0.2) 100%);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  float:left;
  padding: 5px;
  color:white;
  margin: 10px 0 0 -10px;
  font-weight: bold;
}
.boton:hover{
  background: #2d4f63;
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
#contacto{
  margin:15px;
}
#contacto a{
  color: #c1dbea;
}
#contacto .nombre{
  font-weight: bold;
}
</style>
