<template>
  <div id="app">
    <Slideout menu="#menu" panel="#panel" :toggleSelectors="['.toggle-button']" @on-open="open" padding="156">
      <nav id="menu">
        <ul>
          <li><router-link to="/" class="outsider">Portada</router-link></li>
          <li><router-link to="/empresa" class="outsider">¿Quienes somos?</router-link></li>
          <li><router-link to="/proyectos" class="outsider">Proyectos</router-link></li>
          <li><router-link to="/contacto" class="outsider">Contacto</router-link></li>
        </ul>
      </nav>
      <main id="panel">
        <header>
            <div>
                <button class="toggle-button">☰</button>
            </div>
        </header>
        <div class="principal">
          <router-link to="/" class="outsider logo"><img id="logo" src="./assets/Clifon.svg" /></router-link><br>
          <nav id="menuP">
            <ul>
              <li><router-link to="/" class="outsider">Portada</router-link></li>
              <li><router-link to="/empresa" class="outsider">¿Quienes somos?</router-link></li>
              <li><router-link to="/proyectos" class="outsider">Proyectos</router-link></li>
              <li><router-link to="/contacto" class="outsider">Contacto</router-link></li>
            </ul>
          </nav>
          <router-view/>
          <div class="aviso"><router-link to="/avisolegal">Aviso Legal</router-link> © 2017 Clifon Center S.A.<br />Todos los derechos reservados</div>
          </div>
      </main>
    </Slideout>
  </div>
</template>

<script>
import Slideout from 'vue-slideout'

export default {
  name: 'app',
  components: {
    Slideout
  },
  methods: {
    open: function () {
      console.log('slideoutOpen')
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style>
@media screen and (max-width:600px) {
  #menuP{
    display: none;
  }
  h1,p{
    padding-left:10px;
    padding-right:10px;
  }
}
@media screen and (min-width:600px) {
  header{
    display: none;
  }
}
body {
    width: 100%;
    background: linear-gradient(#507e99,#3c5f74) fixed;
}
body, html {
    height: 100%;
    margin: 0;
    font-family: calibri;
    color: #fff;
}
a{
  text-decoration:none;
  color:#c1dbea;
}
a:not(.outsider):hover{
  border-bottom: 1px solid #c1dbea;
  color:#c1dbea;
}
img{
  max-width:100%;
  height:auto;
}
.img{
  margin-bottom: 30px;
}
header{
  margin-bottom: -60px;
}
.toggle-button{
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2em;
  width: 50px;
}
.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 156px;
  min-height: 100vh;
  overflow-y: none;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
  background-color: #214557;
  color: white;
  box-shadow: inset -10px 0 5px 0 rgba(0, 0, 0, 0.15);
}
.slideout-menu-left {
  left: 0;
}
.slideout-menu-right {
  right: 0;
}
.slideout-panel {
  position: relative;
  background: linear-gradient(#507e99,#3c5f74);
  z-index: 1;
  will-change: transform;
  min-height: 100vh;
  overflow: auto;
}
.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}
.slideout-open .slideout-menu {
  display: block;
}
#menu ul{
  list-style-type: none;
  padding: 0;
}
#menu  li a{
  display: block;
  padding: 10px;
  color:#fff;
  font-weight:bold;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
#menu  li a:hover{
  background: #16232a;
  color:#fff;
  font-weight:bold;
}
#menuP {
    margin-bottom: 20px;
    height:30px;
    width:600px;
    background: linear-gradient(to right, rgba(104,140,161,0.2) 0%, rgba(104,140,161,0.7) 10%, rgba(104,140,161,0.7) 90%, rgba(104,140,161,0.2) 100%);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
#menuP  ul {
    margin: 0 auto;
    width:380px;
    list-style-type: none;
    padding: 0;
}
#menuP  li{
  float: left;
}
#menuP  li a{
  display: block;
  padding: 5px 10px;
  color:#fff;
  font-weight:bold;
}
#menuP  li a:hover{
  background: #385d72;
  box-shadow:inset 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  color:#fff;
  font-weight:bold;
}
#logo{
  width: 80%;
}
.principal {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0 auto 30px auto;
  width: 600px;
  max-width:100%;
  display: block;
}
.aviso{
  float: left;
  width: 600px;
  margin: 30px 0;
  font-size: 0.9em;
  max-width:100%;
}
.google-maps {
  max-width:600px;
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0px;
  width: 100% !important;
  height: 100% !important;
}
</style>
