import { render, staticRenderFns } from "./proyectos.vue?vue&type=template&id=3d49ddf4&scoped=true&"
var script = {}
import style0 from "./proyectos.vue?vue&type=style&index=0&id=3d49ddf4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d49ddf4",
  null
  
)

export default component.exports