import Vue from 'vue'
import Router from 'vue-router'
import tarifas from '@/components/tarifas'
import empresa from '@/components/empresa'
import avisolegal from '@/components/avisolegal'
import contacto from '@/components/contacto'
import proyectos from '@/components/proyectos'

Vue.use(Router)

export default new Router({
  routes: [
    { path: '/', name: 'tarifas', component: tarifas },
    { path: '/empresa', name: 'empresa', component: empresa },
    { path: '/proyectos', name: 'proyectos', component: proyectos },
    { path: '/contacto', name: 'contacto', component: contacto },
    { path: '/avisolegal', name: 'avisolegal', component: avisolegal }
  ]
})
